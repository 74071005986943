import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../ScrollToTop";

export default function Layout({ children }) {
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);
  const { sanitySitesettings } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          favicon {
            asset {
              url
            }
          }
          scroll {
            hex
          }

          header {
            logo {
              alt
              asset {
                url
              }
            }
            menu {
              label
              link
              type
            }
          }
          footer {
            logo {
              alt
              asset {
                url
              }
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
          topBar {
            enable
            heading {
              text
              color {
                hex
              }
            }
            link
            bg {
              hex
            }
          }
        }
      }
    `
  );

  const topbar = sanitySitesettings?.topBar;

  return (
    <>
      <Header data={sanitySitesettings?.header} topbar={topbar} />
      <main>{children}</main>
      <ScrollToTop color={sanitySitesettings?.scroll} />

      <Footer data={sanitySitesettings?.footer} />
    </>
  );
}
